// @media print {
//   body {
//     width: 21cm;
//     height: 29.7cm;
//     margin: 20mm 25mm 10mm 25mm;
//     /* change the margins as you want them to be. */
//   }
// }


:global {
  .print-content-wrapper {
    margin: 5mm 5mm 5mm 20mm !important;
    position: relative;
    -webkit-print-color-adjust: exact;
    font-family: 'roboto';
    font-size: 10pt;
    box-sizing: border-box;
  }

  .portrait-print {
    width: 29.7cm;
    height: 21cm;
  }

  .upright-print {
    width: 21cm;
    height: 29.7cm;
  }

  .form-meta {
    font-size: 12pt;
    position: relative;
    height: 50px;
    margin-bottom: 2.5mm;

    //   & > span {
    //     font-weight: bold;
    //   }
    p {
      display: block;
      margin-top: 2mm;

      &>span {
        margin-left: 2.5cm;
      }
    }

    img {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .user-table-wrapper {
    width: calc(80% - 2.5mm);
  }

  .information-container {
    display: flex;

    .standard-information-wrapper:first-of-type {
      width: calc(80% - 2.5mm);
    }

    .standard-information-wrapper:nth-of-type(2) {
      width: 20%;
      justify-content: flex-start;

      p {
        margin-block-start: 0;
        margin-block-end: 0;
        margin-bottom: 1.5mm;
      }
    }
  }

  .standard-information-wrapper {
    position: relative;
    border: 1px solid #000;
    box-sizing: border-box;
    padding: 2.5mm;
    display: flex;
    justify-content: space-between;

    .col {
      display: inline-block;

      p:first-of-type {
        margin-top: 0;
      }
    }
  }

  .station-wrapper {
    display: flex;
    width: 100%;
  }

  .question-table {
    width: 100%;
    font-size: 8pt;
    border-collapse: collapse;
    font-family: 'roboto';
    page-break-inside: avoid !important;
    page-break-before: avoid !important;
    page-break-after: auto !important;

    thead {
      border-bottom: 1px solid #000;
    }

    tr {
      // border-top: 1px solid #000;
      // border-bottom: 1px solid #000;

      th {
        text-align: start;
        // border-right: 1px solid #000;
        // border-left: 1px solid #000;
        background-color: #f3f3f3;
        padding: 5px;
      }
    }

    tbody {
      tr {
        vertical-align: top;
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;

        &.tr-no {
          td {
            font-style: italic;
          }
        }

        &.tr-not-required {
          td {
            color: #c2c2c2;
          }
        }

        &.tr-heading {
          border: none;

          .td-heading {
            font-weight: bold;
            border: none;
            font-size: 11pt;
          }

          .td-sub-heading {
            font-weight: bold;
            border: none;
            font-size: 9pt;
          }
        }

        td {
          padding: 5px;
          // border-right: 1px solid #000;
          // border-left: 1px solid #000;
          word-wrap: break-word;

          &:nth-of-type(2) {
            text-align: center;
            width: 10px;
          }
        }
      }
    }
  }

  //siteStart
  .site-start-information {
    width: 100% !important;

  }

  // utillities
  .flex-col {
    flex-direction: column;
  }

  .mb-25 {
    margin-bottom: 2.5mm;
  }

  .mb-15 {
    margin-bottom: 1.5mm;
  }

  .ml-25 {
    margin-left: 2.5mm;
  }

  .mr-25 {
    margin-right: 2.5mm;
  }
}