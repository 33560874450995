/* General */
:global {
    .logo {
        position: absolute;
        left: -125px;
        top: 125px;
    }

    .container-wrapper {
        position: relative;
        width: 85%;
        margin-left: 13%;
        margin-right: 5%;
        box-sizing: border-box;
        -webkit-print-color-adjust: exact;
    }

    .header-wrapper {
        height: 175px;
    }

    .headerInformation {
        border: 1px solid #000;
        width: 55%;
        height: 70px;
        margin-right: 5%;
        display: inline-block;
        overflow: hidden;
        box-sizing: border-box;
        margin-top: 30px;
        transform: translateY(-90px);
    }

    .headerInformationPara {
        display: inline-block;
        margin-left: 75px;
        font-size: 10pt;
        font-family: "roboto";
        font-weight: bolder;
        color: #0072B6;
        position: relative;
        font-weight: 400;
        transform: translateY(20px);

        &::after {
            position: absolute;
            font-style: italic;
            color: #3a3a3a;
            left: 0px;
            top: -15pt;
            font-size: 8pt;
        }

        &:first-child {
            margin-right: 10px;
            margin-left: 25px;

            &::after {
                content: "KW";
                color: #0072B6;
                font-style: normal;
                font-size: 10pt;
            }
        }

        &:nth-child(2) {
            &::after {
                content: "Arbeit";
            }
        }

        &:nth-child(3) {
            &::after {
                content: "Reise";
            }
        }

        &:nth-child(4) {
            &::after {
                content: "Gesamt";
            }
        }
    }

    .stampArea {
        display: inline-block;
        border: 1px solid #000;
        height: 175px;
        overflow: hidden;
        box-sizing: border-box;
        width: 40%;
        position: relative;
    }

    .signatureImage {
        width: auto;
        height: 100%;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0%);
    }

    .signatureButton {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .stampInformation {
        display: block;
        padding-left: 10px;
        transform: translateY(130px);
        border-top: 1px solid #000;
        padding-top: 5px;
        overflow: hidden;
        box-sizing: border-box;
        font-family: "roboto";
        font-style: italic;
        font-weight: 400;
        font-size: 8pt;
    }


    .information-wrapper {
        display: inline-block;
        transform: translateY(-70px);

        p {
            min-width: 225px;
            display: inline-block;
            font-size: 10pt;
            font-family: "roboto";
            font-weight: bolder;
            color: #0072B6;
            position: relative;
            font-weight: 500;

            &::after {
                color: #3a3a3a;
                position: absolute;
                left: 0px;
                top: 15pt;
                font-style: italic;
                font-size: 9pt;
                font-weight: 400;
            }
        }
    }

    .customer {
        &::after {
            content: "Kunde";
        }
    }

    .user {
        margin-left: 1cm;

        &::after {
            content: "für MA";
        }
    }

    .container-left {
        width: fit-content;
        margin-right: 5%;
        display: inline-block;

        p {
            min-width: 225px;
            display: inline-block;
            font-size: 10pt;
            font-family: "roboto";
            font-weight: bolder;
            color: #0072B6;
            position: relative;
            font-weight: 500;

            &::after {
                color: #3a3a3a;
                position: absolute;
                left: 0px;
                top: 15pt;
                font-style: italic;
                font-size: 9pt;
                font-weight: 400;
            }
        }
    }

    .location {
        &::after {
            content: "Ort/Baustelle";
        }
    }

    .company {
        margin-left: 1cm;

        &::after {
            content: "Firma";
        }
    }

    .info-row {
        position: relative;
    }

    .project-data {
        display: inline-block;
        position: absolute;
        right: 0;
        width: fit-content;

        p {
            display: inline-block;
            position: relative;
            font-size: 10pt;
            font-family: "roboto";
            color: #0072B6;
            font-weight: 400;
            margin-left: 20px;

            &::after {
                color: #3a3a3a;
                position: absolute;
                font-style: italic;
                font-size: 9pt;
                left: 0px;
                top: 15pt;
            }

            &:first-of-type {
                margin-right: 20px;
                margin-left: 0px;

                &::after {
                    content: "Projekt-Nr";
                    width: 70px;
                }
            }

            &:nth-of-type(2) {
                &::after {
                    content: "AB-Nr";
                }
            }

            &.wa-number {
                width: 65px;

                .wa-name {
                    color: #3a3a3a;
                    position: absolute;
                    font-style: italic;
                    font-size: 9pt;
                    left: 0px;
                    top: 15pt;
                }

                &::after {
                    display: none;
                }
            }

            &:last-of-type {
                &::after {
                    content: "Typ Art";
                }
            }
        }
    }

    .entry-wrapper {
        margin-top: 1cm;
        border: 1px solid #000;
        padding: 25px 20px 20px 45px;
        page-break-inside: avoid !important;
        page-break-before: auto !important;
        page-break-after: auto !important;
    }

    .entry-general-information {
        margin-bottom: 15px;
        display: block;

        p {
            display: inline;
            font-size: 9pt;
            position: relative;
            font-family: "roboto";
            margin-left: 1.5cm;

            &::after {
                color: #3a3a3a;
                position: absolute;
                left: 0px;
                top: -15pt;
                font-style: italic;
                font-size: 8pt;
                font-weight: 400;
            }

            &:first-of-type {
                margin-left: -25px;
            }

            &:nth-of-type(2) {
                margin-left: 30px;

                &::after {
                    content: "Arbeit";
                }
            }

            &:nth-of-type(3) {
                &::after {
                    content: "Reise";
                }
            }

            &:nth-of-type(4) {
                &::after {
                    content: "Gesamt";
                }
            }

            &:last-of-type {
                color: #d3d3d3;

                &::after {
                    content: "Pause";
                    color: #d3d3d3;
                }
            }

            span {
                position: absolute;
                left: 0px;
                top: -15pt;
            }
        }
    }

    .entry-table {
        width: 100%;

        font-size: 9pt;
        border-collapse: collapse;
        font-family: "roboto";
        page-break-inside: avoid !important;
        page-break-before: avoid !important;
        page-break-after: auto !important;

        thead {
            tr {
                border-bottom: 1px solid #000;

                th {
                    text-align: start;

                    &:first-of-type {
                        width: 50px;
                    }

                    &:nth-of-type(2) {
                        width: 50px;
                    }

                    &:nth-of-type(3) {
                        width: 50px;
                    }

                    &:nth-of-type(4) {
                        width: 100px;
                    }

                    &:nth-of-type(5) {
                        width: 90px;
                    }

                    &:nth-of-type(6) {
                        width: 95px;
                    }

                    &:nth-of-type(7) {
                        width: 375px;
                    }

                    &:nth-of-type(8) {
                        width: 375px;
                    }
                }
            }
        }

        tbody {
            tr {
                vertical-align: top;

                td {
                    padding-top: 10px;
                    padding-bottom: 5px;
                    word-wrap: break-word;
                }
            }
        }
    }
}