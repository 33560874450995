/* General */
:global {
  @page {
    margin: 0;
  }

  .sp-logo {
    position: absolute;
    top: 125px;
  }

  .sp-container-wrapper {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    -webkit-print-color-adjust: exact;
  }

  .sp-header-wrapper {
    margin-left: 120px;
    display: flex;
  }

  .sp-block {
    display: block;
    width: 100%;
  }

  .sp-headerInformation {
    border: 1px solid #000;
    height: 70px;
    margin-right: 5%;
    display: flex;
    overflow: hidden;
    box-sizing: border-box;
    margin-top: 1cm;
  }

  .sp-headerInformationPara {
    display: block;
    margin-left: 50px;
    font-size: 10pt;
    font-family: 'roboto';
    font-weight: bolder;
    color: #0072b6;
    position: relative;
    font-weight: 400;
    transform: translateY(20px);

    &::after {
      position: absolute;
      font-style: italic;
      color: #3a3a3a;
      left: 0px;
      top: -15pt;
      font-size: 8pt;
    }

    &:first-child {
      margin-right: 10px;
      margin-left: 25px;

      &::after {
        content: 'Service-Einsatz';
        color: #0072b6;
        font-style: normal;
        font-size: 10pt;
      }
    }

    &:nth-child(2) {
      &::after {
        content: 'Arbeit';
      }
    }

    &:nth-child(3) {
      &::after {
        content: 'Reise';
      }
    }

    &:nth-child(4) {
      &::after {
        content: 'Gesamt';
      }
    }
  }

  .sp-stampArea {
    display: block;
    border: 1px solid #000;
    height: 175px;
    overflow: hidden;
    box-sizing: border-box;
    width: 50%;
    position: relative;
    margin-top: 1cm;
  }

  .sp-signatureImage {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .sp-signatureButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .sp-stampInformation {
    display: block;
    padding-left: 10px;
    transform: translateY(130px);
    border-top: 1px solid #000;
    padding-top: 5px;
    overflow: hidden;
    box-sizing: border-box;
    font-family: 'roboto';
    font-style: italic;
    font-weight: 400;
    font-size: 8pt;
  }

  .sp-information-wrapper {
    display: block;

    p {
      min-width: 225px;
      display: inline-block;
      font-size: 10pt;
      font-family: 'roboto';
      font-weight: bolder;
      color: #0072b6;
      position: relative;
      font-weight: 500;

      &::after {
        color: #3a3a3a;
        position: absolute;
        left: 0px;
        top: 15pt;
        font-style: italic;
        font-size: 9pt;
        font-weight: 400;
      }
    }
  }

  .sp-customer {
    &::after {
      content: 'Kunde';
    }
  }

  .sp-user {
    margin-left: 1cm;

    &::after {
      content: 'für MA';
    }
  }

  .sp-container-left {
    width: fit-content;
    display: inline-block;

    p {
      display: inline-block;
      font-size: 10pt;
      font-family: 'roboto';
      font-weight: bolder;
      color: #0072b6;
      position: relative;
      font-weight: 500;

      &::after {
        color: #3a3a3a;
        position: absolute;
        left: 0px;
        top: 15pt;
        font-style: italic;
        font-size: 9pt;
        font-weight: 400;
      }
    }
  }

  .sp-location {
    &::after {
      content: 'Ort/Baustelle';
    }
  }

  .sp-company {
    margin-left: 1cm;

    &::after {
      content: 'FAB';
    }
  }

  .sp-ab {
    margin-left: 1cm;

    &::after {
      content: 'AB';
    }
  }

  .sp-wa {
    margin-left: 1cm;

    &::after {
      content: 'WA';
    }
  }

  .sp-info-row {
    position: relative;
    margin-left: 120px;
  }

  .sp-entry-wrapper {
    margin-top: 1cm;
    border: 1px solid #000;
    padding: 25px 20px 20px 45px;
    page-break-inside: avoid !important;
    page-break-before: auto !important;
    page-break-after: auto !important;
  }

  .sp-entry-general-information {
    margin-bottom: 15px;
    display: block;

    p {
      display: inline;
      font-size: 9pt;
      position: relative;
      font-family: 'roboto';
      margin-left: 1.5cm;

      &::after {
        color: #3a3a3a;
        position: absolute;
        left: 0px;
        top: -15pt;
        font-style: italic;
        font-size: 8pt;
        font-weight: 400;
      }

      &:first-of-type {
        margin-left: -25px;
      }

      &:nth-of-type(2) {
        margin-left: 30px;

        &::after {
          content: 'Arbeit';
        }
      }

      &:nth-of-type(3) {
        &::after {
          content: 'Reise';
        }
      }

      &:nth-of-type(4) {
        &::after {
          content: 'Gesamt';
        }
      }

      &:last-of-type {
        color: #d3d3d3;

        &::after {
          content: 'Pause';
          color: #d3d3d3;
        }
      }

      span {
        position: absolute;
        left: 0px;
        top: -15pt;
      }
    }
  }

  .sp-entry-table {
    width: 100%;

    font-size: 9pt;
    border-collapse: collapse;
    font-family: 'roboto';
    page-break-inside: avoid !important;
    page-break-before: avoid !important;
    page-break-after: auto !important;

    thead {
      tr {
        border-bottom: 1px solid #000;

        th {
          text-align: start;

          &:first-of-type {
            width: 50px;
          }

          &:nth-of-type(2) {
            width: 50px;
          }

          &:nth-of-type(3) {
            width: 50px;
          }

          &:nth-of-type(4) {
            width: 100px;
          }

          &:nth-of-type(5) {
            width: 90px;
          }

          &:nth-of-type(6) {
            width: 95px;
          }

          &:nth-of-type(7) {
            width: 375px;
          }
        }

        th.commentsSmall {
          width: 375px;
        }
        th.commentsWide {
          width: 500px;
        }
      }
    }

    tbody {
      tr {
        vertical-align: top;

        &.gray {
          background-color: #c2c2c2;
        }

        td {
          padding-top: 10px;
          padding-bottom: 5px;
          word-wrap: break-word;
        }
      }
    }
  }
}
