:global {
    .print-content-wrapper {
        margin: 5mm 5mm 5mm 5mm !important;
        position: relative;
        -webkit-print-color-adjust: exact;
        font-family: 'roboto';
        font-size: 10pt;
        box-sizing: border-box;
    }

    .portrait-print {
        width: 29.7cm;
        height: 21cm;
    }

    .upright-print {
        width: 21cm;
        height: 29.7cm;
    }

    .visit-header-wrapper {
        display: flex;
        align-items: center;

        border: 1px solid #000;
        padding-right: 5mm;
        margin-bottom: 5mm;

        .heading-wrapper {
            flex-grow: 1;
            border-right: 1px solid #000;
            margin-right: 5mm;

            p {
                text-align: center;
                font-size: 2.4rem;
                font-weight: bold;
            }

            img {}
        }
    }

    table.visit-info {
        width: 100%;
        border: 1px solid #000;
        // padding: 2.5mm;
        margin-bottom: 5mm;
        border-collapse: collapse;
        border-spacing: 2px;

        tr {
            border-bottom: 1px solid #000;
        }

        td {
            font-size: 1.3rem;
            padding: 1.25mm;

            &:first-of-type {

                border-right: 1px solid #000;

            }

            // font-weight: bold;
        }
    }

    table.visit-entries {

        width: 100%;
        border: 1px solid #000;
        // padding: 2.5mm;
        border-collapse: collapse;
        border-spacing: 2px;

        th {
            margin-bottom: 5mm;
            background-color: #c2c2c2;
        }

        tr {
            border-bottom: 1px solid #000;
        }

        td,
        th {
            font-size: 1.3rem;
            padding: 1.25mm;

            &:not(last-of-type) {

                border-right: 1px solid #000;

            }

            // font-weight: bold;
        }
    }
}